import React from 'react';
import { connect } from 'react-redux';

import { screen } from '../../../components/hoc';

import CommonScreen from './common';
import CurrentStateModel from '$fbusiness/models/currentState';

interface ReceivingScreenProps {
  currentState: CurrentStateModel;
  columns;
}

const ReceivingScreen: React.FC<ReceivingScreenProps> = ({ currentState, columns }) => {
  return (
    <CommonScreen
      isReceiving
      currentState={currentState}
      columns={columns || []}
      defaultFilter={{
        query: '',
        tableTabIndex: 0,
        confirmedAt: true,
        storeFlag: 1,
        received: 0,
        forceRefresh: false,
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  columns: state.localStorage.columns,
  resetOnRoute: true,
});

const connected = connect(mapStateToProps, null);

export default connected(screen(ReceivingScreen));
